@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInLeft {
   0% {
      opacity: 0;
      transform: translateX(-20px);
   }
   100% {
      opacity: 1;
      transform: translateX(0);
   }
}

@keyframes fadeInRight {
   0% {
      opacity: 0;
      transform: translateX(20px);
   }
   100% {
      opacity: 1;
      transform: translateX(0);
   }
}

@keyframes zoomOut {
    0% {
        opacity: 0;
        transform: scale(.5) translateY(40px);
    }

    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@mixin fade-in-animation($seconds: .75s) {
    animation: fadeIn $seconds;
    opacity: 1;
    transition: opacity 600ms linear;
}

$fade-in-x: 800ms;

@mixin fade-in-left($seconds: $fade-in-x) {
    animation: fadeInLeft $seconds;
    opacity: 1;
    transition: opacity $fade-in-x linear;
}

@mixin fade-in-right($seconds: $fade-in-x) {
    animation: fadeInRight $seconds;
    opacity: 1;
    transition: opacity $fade-in-x linear;
}

@mixin fade-in-up($seconds: $fade-in-x) {
    animation: fadeInUp $seconds;
    opacity: 1;
    transition: opacity $fade-in-x linear;
}

.fade-in-animation {
    @include fade-in-animation();   
}

.fade-in-animation-1s {
    @include fade-in-animation(1s);   
}

.fade-in-left {
    @include fade-in-left();    
}

.fade-in-right {
    @include fade-in-right();    
}

.fade-in-up {
    @include fade-in-up();    
}