@import '../../assets/sass/main.scss';

.about-container {
    @include fade-in-animation(1s);
    @extend .noselect;

    height: fit-content;
    background-color: white;

    .about-content {
        position: relative;
        background: white;
        overflow: hidden;
        height: 100%;

        &::after {
            background-image: url('../../assets/img/marlin-icon.png');
            background-size: cover;
            background-position: 50% 0;
            background-repeat: no-repeat;
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0.08;
        }

        .title {
            background: lighten(color(primary), 5%);
            padding: 2em;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                text-transform: uppercase;
                font-family: font(header);
                font-size: 28pt;
                color: white;
                padding: 0;
                margin: 0;
            }
        }

        .title-alt {
            @extend .title;
            background: transparent;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: lighten(color(primary), 5%);
            }
        }

        .content {
            width: 70%;
            margin: 0 auto;
            font-size: 12pt;
            padding: 4.5em 0;
            text-align: center;

            p {
                color: color(secondary);
                font-family: font(text);
            }
        }

        .ceo-message {
            width: 70%;
            margin: 0 auto;
            padding: 7em 0;

            img {
                width: 300px;
                height: 450px;
                margin-bottom: 2em;
                border: 1px solid color(primary);
                border-radius: 0.3rem;
            }

            .message {
                color: color(secondary);
                font-size: 12pt;
                text-align: left;
                font-family: font(text);
            }
        }

        .divider {
            width: 70%;
        }

        .btn-group {
            @extend .title;
            width: 100%;
            padding: 1em;
            margin: 0 auto;
            z-index: 2;
        }
    }
}
