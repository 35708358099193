@import '../../assets/sass/main.scss';

.add-margin-top {
    margin-top: 4em;
    height: 300px;
}

@media (min-width: 960px) and (max-width: 1100px) {
    .add-margin-top {
        height: 250px;
    }
}

@media (min-width: 1600px) and (max-width: 1800px) {
    .add-margin-top {
        height: 400px;
    }
}

@media (min-width: 1800px) {
    .add-margin-top {
        height: 450px;
    }
}

.background-container {
    @include fade-in-animation(1s);

    position: relative;
    height: max-content;
    padding-bottom: 2em;
    width: 100vw;

    .header {
        background: rgba(black, 0.8);
        width: 100%;
        padding: 2em;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            text-transform: uppercase;
            font-family: font(header);
            font-size: 28pt;
            color: white;
            padding: 0;
            margin: 0;
            @extend .noselect;
        }
    }
}

.products-container {
    #current-product {
        width: 80%;
        height: calc((100% * 16) / 9);
        margin: 0 auto;

        @extend .noselect;

        .button {
            @extend .add-margin-top;

            position: relative;
            height: 300px;

            svg {
                @extend .noselect;

                position: absolute;
                top: 50%;
                bottom: 50%;
                transform: translate(-50%, -50%);

                transition: 500ms;
                font-size: 40px;
                color: white;

                &:hover {
                    transition: 500ms;
                    color: lighten(color(primary), 30%);
                    cursor: pointer;
                }

                &:active {
                    transition: 500ms;
                    color: white;
                }
            }
        }

        .image {
            @extend .add-margin-top;
            // height: calc((100% * 16)/9);

            img {
                width: 100%;
                height: 100%;
            }
        }

        .info {
            @extend .add-margin-top;
            // height: calc((100% * 16)/9);

            .info-wrapper {
                height: 100%;
                background: rgba(black, 0.54);
                padding: 2em;

                h1,
                p {
                    color: white;
                    text-align: left;
                }

                h1 {
                    font-size: 20px;
                    font-family: font(header);
                }

                p {
                    font-size: 13px;
                    font-family: font(text);
                }
            }
        }
    }

    .product-list {
        @extend .noselect;
        background: rgba(black, 0.75);
        margin: 1em auto;
        height: max-content;
        padding: 2.5em;
        width: 73.33%;

        $animation-speed: 500ms;

        .product-list-item {
            margin-bottom: 1em;

            .image {
                background-color: rgba(white, 0.8);
                text-align: center;
                border-radius: 0.3rem;

                img {
                    transition: $animation-speed;

                    margin: 0;
                    padding: 0;
                    width: 100%;
                    height: calc((100% * 16) / 9);
                    border-radius: 0.3rem;
                }
            }

            .name {
                margin-top: 0.5em;
                text-align: center;

                p {
                    transition: $animation-speed;

                    margin: 0;
                    color: white;
                    font-size: 13px;
                    font-family: font(header);
                    text-transform: capitalize;
                }
            }

            &:hover {
                cursor: pointer;
            }

            &:active {
                .image {
                    img {
                        transition: $animation-speed;

                        $color: darken(color(primary), 20%);

                        box-shadow: 0px -1px 6px 6px $color;
                        filter: brightness(75%);
                    }
                }

                .name {
                    p {
                        transition: $animation-speed;
                        opacity: 0.6;
                    }
                }
            }

            &.active,
            &:hover {
                .image {
                    img {
                        transition: $animation-speed;

                        $color: darken(color(primary), 20%);

                        box-shadow: 0px -1px 6px 6px $color;
                        filter: brightness(50%);
                    }
                }

                .name {
                    p {
                        transition: $animation-speed;
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .background-container {
        height: 100%;
        width: 100vw;
    }
    $margin-top: 0;
    $height: 200px;

    .products-container {
        user-select: none;
        #current-product {
            width: 90%;
            margin: 0 auto;

            .image {
                background: lighten(rgba(black, 0.54), 10%);
                margin: 0 auto;
                margin-top: 2em;
                height: calc((100% * 16) / 9);
                width: 100%;
                position: relative;

                .title {
                    position: absolute;
                    top: 0;
                    margin-top: $margin-top;
                    height: max-content;
                    width: 100%;

                    background: rgba(black, 0.45);
                    padding: 0.25em;
                    padding-top: 0.4em;

                    h1 {
                        text-align: center;
                        color: white;
                        font-size: 12px;
                        font-family: font(header);
                    }
                }

                .description {
                    position: absolute;
                    bottom: 0;
                    margin-top: $margin-top;
                    height: max-content;
                    width: 100%;

                    background: rgba(black, 0.45);
                    padding: 0.5em;

                    p {
                        color: white;
                        text-align: center;
                        font-size: 9px;
                        font-family: font(text);
                    }
                }

                img {
                    width: 100%;
                    height: calc((100% * 16) / 9);
                }

                svg {
                    position: absolute;
                    top: 50%;
                    bottom: 50%;
                    transform: translate(-50%, -50%);

                    transition: 500ms;
                    font-size: 40px;
                    color: white;

                    &.left-button {
                        left: 20px;
                    }

                    &.right-button {
                        right: -6px;
                    }
                }
            }
        }

        .product-list {
            user-select: none;
            background: rgba(black, 0.75);
            margin: 0.75em auto;
            height: max-content;
            padding: 1em;
            padding-top: 2em;
            width: 90%;

            $animation-speed: 500ms;

            .product-list-item {
                margin-bottom: 1em;

                .image {
                    height: 100%;
                    background-color: rgba(white, 0.8);
                    text-align: center;
                    border-radius: 0.3rem;

                    img {
                        transition: $animation-speed;

                        margin: 0;
                        padding: 0;
                        width: 100%;
                        height: calc((100% * 16) / 9);
                        border-radius: 0.3rem;
                    }
                }

                .name {
                    margin-top: 0.5em;
                    text-align: center;

                    p {
                        transition: $animation-speed;

                        margin: 0;
                        color: white;
                        font-size: 8px;
                        font-family: font(header);
                        text-transform: capitalize;
                    }
                }

                &:hover {
                    cursor: pointer;
                }

                &:active {
                    .image {
                        img {
                            transition: $animation-speed;

                            $color: darken(color(primary), 20%);

                            box-shadow: 0px -1px 6px 6px $color;
                            filter: brightness(75%);
                        }
                    }

                    .name {
                        p {
                            transition: $animation-speed;
                            opacity: 0.6;
                        }
                    }
                }

                &.active,
                &:hover {
                    .image {
                        img {
                            transition: $animation-speed;

                            $color: darken(color(primary), 20%);

                            box-shadow: 0px -1px 6px 6px $color;
                            filter: brightness(50%);
                        }
                    }

                    .name {
                        p {
                            transition: $animation-speed;
                            opacity: 0.6;
                        }
                    }
                }
            }
        }
    }
}
