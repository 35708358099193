@import '../../assets/sass/main.scss';

.home-container {
    @include fade-in-animation(1s);

    position: relative;
    height: 100vh;
    width: 100vw;

    .intro-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
            height: 250px;
            width: 375px;
            margin-bottom: 4em;

            animation: zoomOut 2s;
        }

        h1 {
            font-size: 25px;
            color: white !important;
            text-shadow: 1px 1px darken(color(primary), 15%);
            font-family: font(intro);
            margin: 0 0 0.25em 0;

            animation: fadeInUp 1s;
        }

        p {
            font-size: 19px;
            color: lighten(color(accent), 20%) !important;
            text-shadow: 1px 1px darken(color(primary), 15%);
            font-family: font(intro-bottom);
            margin: 0;

            animation: fadeInUp 1.5s;
        }

        .about-btn {
            @include fade-in-animation(1.5s);

            animation: fadeInUp 2s;
        }
    }
}

@media (max-width: getBreakpoint(mobile)) {
    .home-container {
        .intro-container {
            padding: 6em 0;
            h1 {
                font-size: 16pt;
                color: white !important;
            }

            p {
                font-size: 10pt;
                color: white;
                font-family: font(text);
            }

            img {
                margin: 2em 0;
                height: 200px;
                width: 300px;
            }

            .about-btn {
                margin-top: 4em;
            }
        }
    }
}
