@import '../../assets/sass/main.scss';

.footer {
    background-color: darken(color(primary), 15%);
    font-family: font(footer);
    color: font-color(white);
    font-size: 14px;
    padding: 0.5em;

    @extend .z-depth-1;
}

@media (max-width: 960px) and (min-width: 640px) {
    .footer {
        font-size: 12px;
    }
}

@media (max-width: 640px) {
    .footer {
        font-size: 12px;
    }
}
