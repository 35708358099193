// Color Scheme
$color-scheme: (
    primary: darken(#04BED0, 10%),
    secondary: #263A6C,
    accent: #8BECFF,
    xxx: #258FD1,
    white: #FFFFFF
);

$font-color-scheme: (
    dark: #263A6C,
    light: #04BED0,
    white: white
);

@function color($key: "primary") {
    @return map-get($color-scheme, $key);
}

@function font-color($key: "dark") {
    @return map-get($font-color-scheme, $key);
}