@import '../../assets/sass/main.scss';

.error404-container {
    @include fade-in-animation(1s);

    height: 100vh;
    width: 100vw;
    position: relative;

    .header {
        background: rgba(black, 0.8);
        padding: 2em;

        h1 {
            text-transform: uppercase;
            font-family: font(header);
            font-size: 28pt;
            color: white;
            padding: 0;
            margin: 0;
        }
    }

    .message-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
            height: 200px;
            width: 300px;
        }

        .home-btn {
            text-transform: none !important;
        }
    }
}

@media (max-width: getBreakpoint(mobile)) {
    .error404-container {
        height: 100vh;
        width: 100vw;

        .message-container {
            padding: 4.8em;
            h1 {
                color: white !important;
            }

            p {
                font-size: 26pt;
                color: white;
                font-family: font(text);
            }

            img {
                height: 200px;
                width: 300px;
            }

            .more-info-btn {
                margin-top: 4em;
            }
        }
    }
}
