// @import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

// @font-face {
//     font-family: Blacksword;
//     src: url("./collection/Blacksword.otf") format("opentype");
// }

$fonts: (
    header: ('Lato', sans-serif),
    text: ('Open Sans,' sans-serif),
    button: ('Roboto', sans-serif),
    intro: ('Lato', sans-serif),
    intro-bottom: ('Open Sans,' sans-serif),
    navbar: ('Lato', sans-serif),
    footer: ('Lato', sans-serif),
    infobar: ('Lato', sans-serif)
);

@function font($key: "primary") {
    @return map-get($fonts, $key);
}