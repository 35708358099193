@import '../../assets/sass/main.scss';

.navbar {
    @extend .z-depth-1;
    @extend .noselect;
    background: color(white) !important;
    text-align: center;
    margin: 0 auto;
    width: 100vw;

    .items-container {
        width: 70%;
        overflow: hidden;
        margin: 0 auto;

        .logo {
            height: 40px;
            margin-top: 20px;
            float: left;
        }

        ul {
            margin: 0;
            padding: 0;
            float: right;

            li {
                list-style-type: none;
                display: inline-block;

                a {
                    transition: 0.5s;
                    line-height: 80px;
                    padding: 31px 24px;

                    font-family: font(navbar);
                    text-transform: uppercase;
                    font-weight: 600;
                    text-decoration: none;
                    color: font-color(dark);
                    border-bottom: 1px solid transparent;

                    &:hover {
                        transition: 0.5s;
                        color: font-color(light);
                        border-bottom: 1px solid font-color(dark);
                    }

                    &.active {
                        color: lighten(font-color(dark), 15%);
                        border-bottom: 1px solid font-color(dark);
                    }

                    &:active {
                        color: lighten(font-color(dark), 15%);
                    }
                }
            }
        }
    }
}

@media (max-width: getBreakpoint(mobile)) {
    .navbar {
        .items-container {
            width: 97.5%;

            .logo {
                height: 24px;
                margin-top: 12.5px;
                float: left;
            }

            ul li a {
                line-height: 50px;
                padding: 20px 5px;
                font-size: 8px;
            }
        }
    }
}

@media (max-width: 900px) and (min-width: getBreakpoint(mobile)) {
    .navbar {
        .items-container {
            width: 90%;

            .logo {
                height: 40px;
                float: left;
            }

            ul li a {
                padding: 31px 12px;
                font-size: 10pt;
            }
        }
    }
}

@media (min-width: 900px) and (max-width: 1070px) {
    .navbar {
        .items-container {
            ul li a {
                padding: 31px 12px;
                font-size: 10pt;
            }
        }
    }
}
