@import '../../assets/sass/main.scss';

.iframe-wrapper {
    @extend .z-depth-1;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 400px;
    border-radius: 1rem;
    background-color: white;

    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

.contact-form {
    @extend .z-depth-1;
    padding-top: 3em !important;
    padding-right: 2em !important;
    padding-left: 2em !important;
    background-color: white;
    border-radius: 1rem;
    height: 400px;
}

.content-col {
    padding: 1em 3em;
}

.contact-container {
    @include fade-in-animation(1s);

    position: relative;
    background: transparent;
    overflow: hidden;
    height: auto;
    padding-bottom: 2em;

    .divider {
        border-top: 1pt solid lighten(color(primary), 5%);
    }

    .header {
        background: lighten(color(primary), 5%);
        padding: 2em;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            text-transform: uppercase;
            font-family: font(header);
            font-size: 28pt;
            color: white;
            padding: 0;
            margin: 0;
        }
    }

    .content {
        h2 {
            color: darken(color(primary), 15%);
        }
        margin: 2em auto;
        max-width: 1580px;

        .contact {
            background-color: white;
            border: 1px solid color(primary);
            border-radius: 0.5rem;
            padding: 1.2em;
            width: 30%;
            display: inline-block;

            h3 {
                margin-bottom: 2px;
            }

            a {
                color: darken(color(primary), 15%);
            }

            &:nth-child(2) {
                margin: 0 2em 0 2em;
            }
        }
    }

    &::after {
        background-image: url('../../assets/img/marlin-icon.png');
        background-size: cover;
        background-position: 50% 0;
        background-repeat: no-repeat;
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -999;
        opacity: 0.08;
    }
}

@media (max-width: 640px) {
    .contact-container {
        .content {
            .contact {
                display: block;
                width: 80%;
                margin: 0 auto;

                &:nth-child(2) {
                    margin: 2em auto;
                }
            }
        }
    }

    .contact-form {
        height: 100%;
        padding-bottom: 1em !important;
    }
}
