.App {
    text-align: center;
    height: 100vh;
    width: 100%;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
