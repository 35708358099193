@import './color-scheme.scss';
@import './fonts/fonts.scss';

.divider {
    border: none;
    border-top: 1pt solid color(primary);
}

.dotted-divider {
    border: none;
    border-top: 3px dotted color(primary);
}

.ant-btn {
    font-family: font(button) !important;
    text-transform: capitalize !important;

    transition: 400ms !important;
    background: rgba(darken(color(primary), 10%), 0.6) !important;
    border: 1px solid darken(color(primary), 5%) !important;
    color: rgb(252, 252, 252) !important;

    &:hover {
        transition: 400ms !important;
        background: rgba(darken(color(primary), 10%), 0.9) !important;
        border: 1px solid darken(color(primary), 25%) !important;
        color: rgb(252, 252, 252) !important;
    }

    &:active {
        transition: 400ms !important;
        background: rgba(darken(color(primary), 5%), 1) !important;
        border: 1px solid darken(color(primary), 15%) !important;
        color: rgb(252, 252, 252) !important;
    }

    &:disabled {
        transition: 400ms !important;
        background: lighten(lightgray, 5%) !important;
        border: 1px solid black !important;
        color: black !important;
    }
}

.background-image {
    position: absolute;
    left: 0;
    top: 0;

    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;
}
