.z-depth-0 {
  box-shadow: none !important;
}

/* 2dp elevation modified*/
.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),
              0 3px 1px -2px rgba(0,0,0,0.12),
              0 1px 5px 0 rgba(0,0,0,0.2);
}
.z-depth-1-half {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

/* 6dp elevation modified*/
.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),
              0 1px 10px 0 rgba(0,0,0,0.12),
              0 2px 4px -1px rgba(0,0,0,0.3);
}

/* 12dp elevation modified*/
.z-depth-3 {
  box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14),
              0 3px 14px 2px rgba(0,0,0,0.12),
              0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

/* 16dp elevation */
.z-depth-4 {
  box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14),
              0 6px 30px 5px rgba(0,0,0,0.12),
              0 8px 10px -7px rgba(0,0,0,0.2);
}

/* 24dp elevation */
.z-depth-5 {
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14),
              0 9px 46px 8px rgba(0,0,0,0.12),
              0 11px 15px -7px rgba(0,0,0,0.2);
}

.hoverable {
  transition: box-shadow .25s;

  &:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}