@import '../../assets/sass/main.scss';

.info-bar {
    background: color(primary);
    font-family: font(infobar);
    color: color(white);
    padding: 0.25em;
    width: 100vw;

    .text-container {
        width: 70%;
        font-size: 9pt;
        margin: 0 auto;

        a {
            text-decoration: none;
            color: white;
        }

        .address {
            float: left;
        }

        .contact {
            float: right;
        }
    }
}

@media (max-width: getBreakpoint(mobile)) {
    .info-bar {
        .text-container {
            width: 97.5%;
            font-size: 5pt;
        }
    }
}

@media (max-width: 900px) and (min-width: getBreakpoint(mobile)) {
    .info-bar {
        .text-container {
            width: 90%;
            font-size: 9pt;
        }
    }
}
