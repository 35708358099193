@import '../../assets/sass/main.scss';

.factory-container {
    @include fade-in-animation(1s);

    .our-factory,
    .our-process {
        position: relative;
        height: 100vh;

        $header-color: darken(color(primary), 15%);

        .header {
            background: lighten(color(primary), 5%);
            padding: 2em;

            h1 {
                text-transform: uppercase;
                font-family: font(header);
                font-size: 28pt;
                color: white;
                padding: 0;
                margin: 0;
            }
        }

        .technical-details {
            position: absolute;
            top: 130px;
            width: 220px;
            right: 0;
            background: rgba(lighten(color(primary), 5%), 0.7);
            padding: 1em;

            h1 {
                font-family: font(header);
                color: white;
                font-size: 16px;
                text-align: left;
            }

            p {
                font-family: font(text);
                color: white;
                font-size: 12px;
                text-align: left;
                margin: 0;
                margin-top: 2px;
            }
        }

        .factory-details,
        .process-details {
            position: absolute;
            width: 100vw;
            bottom: 0;
            left: 0;
            background: rgba($header-color, 0.8);
            padding: 2em;

            .data-wrapper {
                width: 70%;
                margin: 0 auto;
                h1 {
                    font-family: font(header);
                    font-size: 15px;
                    color: white;
                    font-weight: bold;
                }

                p {
                    font-family: font(text);
                    font-size: 11px;
                    color: white;
                }

                @media (min-width: 960px) {
                    h1 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .factory-container {
        .our-process,
        .our-factory {
            .data-wrapper {
                width: 95% !important;
            }
        }
    }
}
