@import '../../assets/sass/main.scss';

.loading-container {
    $color-1: darken(color(primary), 10%);
    $color-2: color(primary);
    $color-3: lighten(color(primary), 10%);

    background: color(primary);
    background: linear-gradient(150deg, $color-1 0%, $color-2 50%, $color-3 100%);

    position: relative;
    height: 100vh;

    .loading-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.ripple-loading {
    width: 300px;
    height: 300px;
    display: inline-block;
    overflow: hidden;
    background: transparent;

    .inner {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0;

        div {
            position: absolute;
            border-width: 6px;
            border-style: solid;
            opacity: 1;
            border-radius: 50%;
            animation: loading-animation 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            box-sizing: content-box;

            &:nth-child(2) {
                border-color: lighten(color(primary), 5%);
                animation-delay: -0.5s;
            }

            &:nth-child(1) {
                border-color: darken(color(primary), 5%);
            }
        }
    }
}

@keyframes loading-animation {
    0% {
        top: 144px;
        left: 144px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 57px;
        left: 57px;
        width: 174px;
        height: 174px;
        opacity: 0;
    }
}
